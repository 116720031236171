import React from 'react';
import logo from './eng_gear.png';
import linkedin_logo from './Linkedin-logo.png';
import github_logo from './github-logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Work In Progress ...
        </p>
        <div className='SocialMedia'>
          <p>Lets connect on  : </p>
          <a href = "https://www.linkedin.com/in/dev-udayanbaidya/">
            <img src={linkedin_logo} className="SocialMedia-logo" width="80px" height="50px" alt="linkedin-logo" />
          </a>
          <a href = "https://github.com/udaycoder">
            <img src={github_logo} className="SocialMedia-logo" width="60px" height="50px" alt="github-logo" />
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
